import React, { lazy } from "react";
import header_logo from "../../assets/twooca_ring_white_wide.svg";
import header_logo1 from "../../assets/twooca_ring_white.svg";
import ringDesc from "../../assets/ring/ring.png";
import appImg1 from "../../assets/ring/app1.png";
import appImg2 from "../../assets/ring/app2.png";
import box from "../../assets/ring/box.png";
import "../../styles/ring/index.css";

const NavBottomBtn = lazy(
  () => import("../../components/bottomBtnForPhone/NavBottomBtn")
);
const Cooperate = lazy(() => import("./section/Cooperate"));
const ContactBtn = lazy(() => import("../../components/contactBtn/ContactBtn"));
const ServiceDetail = lazy(() => import("./ringSection/ServiceDetail"));
const Footer = lazy(() => import("../../components/footer/Footer"));

const RingHomeView: React.FC = () => (
  <div className="w-full">
    {/* fixed Button */}
    <ContactBtn isWallet={false} />
    {/* fixed Button for phone */}
    <NavBottomBtn isWallet={false} />
    {/* hero section */}
    <div
      className="h-screen md:h-[30rem] xl:h-screen relative flex flex-col w-full px-[5%] md:pl-[5%] pr-0 bg-hero-ring
  bg-cover bg-center overflow-hidden"
    >
      <div className="absolute inset-x-0 inset-y-0 z-20 bg-black opacity-20" />
      {/* ※2023年8月末時点 当社調べ */}
      <div className="hidden md:block absolute left-[5%] bottom-3 z-30 text-hero-detail1">
        ※2023年8月末時点 当社調べ
      </div>
      {/* logo */}
      <div className="flex justify-center md:justify-start items-center z-30 mt-8 md:mt-14 xl:mt-12 ">
        <picture>
          <source
            media="(max-width: 767px)"
            srcSet={header_logo1}
            sizes="767px"
          />
          <source
            media="(min-width: 767px)"
            srcSet={header_logo}
            sizes="1000px"
          />
          <img
            src={header_logo}
            alt="Kort Valuta Logo"
            className="w-[15rem] md:w-[25rem] xl:w-[35rem]"
          />
        </picture>
      </div>
      {/* content */}
      <div className="absolute block z-30 bottom-[40%] md:bottom-[5rem]">
        <p className="w-full pr-3 md:-translate-y-20 text-hero-title">
          世界初&#42;のVisaタッチ決済に対応した
          <br />
          健康管理機能付きリング型ウェアラブルデバイス
        </p>
      </div>
    </div>
    <div className="m-auto box-border content px-8">
      <p className="w-full m-auto py-16 lg:py-28 xl:py-40 text-service-title">
        健康状態や心のバランスを、ずっと見守り
        <br />
        健康な日常を、かんたんに
      </p>
      <p className="text-service-detail1">
        キャッシュレス決済が進む中、スマートフォンによる決済は利便性の高いものとなっています。
        一方、日々の行動において、決済の度にスマホをポケットや鞄から出して、顔認証や指紋認証すら煩わしい、
        決済をおこなわなければならない使いづらさが存在します。
        これらの煩わしさをよりシームレスな体験として利便性を求めた結果、リングという形に至りました。
      </p>
      <br />
      <p className="text-service-detail1">
        また、世界*で初めての試みとして、決済だけでなく、同時に健康を管理出来るリング型ウェアラブルデバイスとして進化させました。
        スマートフォンも、スマートウォッチもいらない、このリングを身に着けるだけで全てが完結する、そしてファッションアイテムとして溶け込み、
        利用者のそばにいつでもいる。そんな形を目指しています。
      </p>
      <br />
      <p className="text-service-small">※2023年8月末時点 当社調べ</p>
      <div className="video_content">
        <iframe
          className="video_content__video"
          src="https://www.youtube.com/embed/mpHwA_B7FSc?si=128HjEpgcjBBAwKZ"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          loading="lazy"
        />
      </div>
    </div>
    <div className="w-full m-auto bg-gray-100">
      {/* FlexibleChargingOptions */}
      <div className="m-auto px-[6.9%] md:px-[5.5%] xxl:px-24 py-20 lg:py-24 xl:py-36 content">
        <div className="pt-14 px-2 md:pt-0 pb-12 md:pb-0 md:pl-10 lg:px-[7%] rounded-3xl bg-white">
          {/* トップアップの概要 */}
          <h1 className="text-ring-title">
            TwooCa Ringは
            <br />
            アプリで毎日の健康を可視化できます
          </h1>
          {/* より便利な指紋およびセンサー機能 */}
          <div className="md:flex">
            <div className="flex md:basis-1/2 w-full justify-center items-center">
              {/* ユートベ紹介動画 */}
              <div className="w-auto xxl: px-[7%] md:px-0 ">
                <img
                  src={ringDesc}
                  alt="ringDesc"
                  className="w-auto m-auto px-[2.5%] md:px-0 "
                  loading="lazy"
                />
              </div>
            </div>
            {/* 従来のトップアップのジレンマの例図 */}
            <div className="md:basis-1/4 pr-2 py-10 md:py-20">
              <img
                src={appImg1}
                alt="appImg1"
                className="w-auto m-auto px-[2.5%] md:px-0"
                loading="lazy"
              />
            </div>
            <div className="md:basis-1/4 pl-2 py-10 md:py-20">
              <img
                src={appImg2}
                alt="appImg2"
                className="w-auto m-auto px-[2.5%] md:px-0"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* IntegratedServices */}
    <div className="m-auto box-border content">
      <p className="w-full m-auto py-16 lg:py-32 xl:py-48 text-service-title">
        TwooCa Ringは
        <br />
        幅広いニーズに対応します
      </p>
      <ServiceDetail />
    </div>
    {/* VISACard */}
    <div className="w-full m-auto bg-gray-100 pb-12 md:pb-40">
      <p className="w-full m-auto py-16 lg:py-28 xl:py-40 text-service-title">
        TwooCa Ring
        <br />
        製品情報
      </p>
      <div className="md:flex content m-auto">
        {/* ビザカード */}
        <div className="md:basis-2/6 m-auto pb-10 md:m-0 md:ml-5 lg:ml-7 xl:ml-10 xxl:ml-9">
          <img
            src={box}
            alt="imgRing1"
            className="px-10 m-auto rounded-3xl"
            loading="lazy"
          />
        </div>
        {/* Visaカードを使用する方が便利です */}
        <div className="md:basis-3/6 md:ml-[2%] xxl:ml-[2.6%]">
          <div className="grid md:grid-cols-[20%_80%] px-5 md:gap-6">
            <p className="text-product-title1">商品名</p>
            <p className="text-product-detail1">TwooCa Ring</p>
            <p className="text-product-title1">型番</p>
            <p className="text-product-detail1">TR G01</p>
            <p className="text-product-title1">材質</p>
            <p className="text-product-detail1">
              内径 セラミック 側面 プラスチック リング表面
              カーボンファイバー／エポキシ樹脂
            </p>
            <p className="text-product-title1">防水性</p>
            <p className="text-product-detail1">IPX6 およびIPX7</p>
            <p className="text-product-title1">同梱品</p>
            <p className="text-product-detail1">
              リング本体 チャージャー 取扱説明書 Welcome リーフレット
            </p>
            <p className="text-product-title1">通信方式</p>
            <p className="text-product-detail1">
              ヘルス機能部分:Bluetooth Low Energy 4.0 決済機能部分:NFC(ISO/IEC
              18092) (ISO/IEC 14443
            </p>
            <p className="text-product-title1">特記事項</p>
            <p className="text-product-detail1">
              フル充電にて約4 日間連続利用可能、充電時間は約90 分
              （ただし、アプリの使い方や劣化により前後致します）
              <br />
              <br />
              本製品の決済機能はプリペイドカード型のVisa タッチ決済に
              対応しており有効期限があります （約５年※製造時期により異なります）
              <br />
              <br />
              リチウムイオン電池の特性上、長期間 (6 ヶ月以上
              充電せずに放置した場合は過放電により 再度充
              電することができなくなることがあります。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Cooperate isWallet={false} />
    <Footer />
  </div>
);

export default RingHomeView;
