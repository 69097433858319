export interface ButtonIsShown {
  isButtonShow: boolean;
}

const initButtonState: ButtonIsShown = {
  isButtonShow: true,
};

export const BUTTONSHOWCASE = "BUTTONSHOWCASE";

const reducers = (
  // eslint-disable-next-line default-param-last
  state: ButtonIsShown = initButtonState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: string; payload: any }
) => ({ ...state, ...action.payload });

export default reducers;
