import React from "react";
import Footer from "../../components/footer/Footer";
import WebView from "../../components/webView/WebView";
import Header from "../../components/navBar/Header";

interface isWalletProp {
  isWallet: boolean;
}
const url = "https://airtable.com/embed/appAbgE8YNvca1uZE/shrZYlNBh2nKBCoz4";

const ContactView: React.FC<isWalletProp> = ({ isWallet }) => (
  <div>
    <Header isWallet={isWallet} />
    <WebView url={url} />
    <Footer />
  </div>
);

export default ContactView;
