import React from "react";
import { Link } from "react-router-dom";
import Envelops from "../../assets/envelope.svg";
import ring_logo from "../../assets/twooca_ring_white_wide.svg";
import wallet_logo from "../../assets/twooca_wallet_white_wide.svg";
import ring_logo1 from "../../assets/twooca_ring_white.svg";
import wallet_logo1 from "../../assets/twooca_wallet_white.svg";

interface isWalletProp {
  isWallet: boolean;
}
const Header: React.FC<isWalletProp> = ({ isWallet }) => (
  <div
    className="flex justify-center md:justify-between
    items-center w-full h-28 md:h-16 px-[5%] bg-common-blue"
  >
    <div className="flex items-center">
      <Link to={isWallet ? "/" : "/ring"} className="">
        <picture>
          <source
            media="(max-width: 767px)"
            srcSet={isWallet ? wallet_logo1 : ring_logo1}
            sizes="767px"
          />
          <source
            media="(min-width: 767px)"
            srcSet={isWallet ? wallet_logo : ring_logo}
            sizes="1000px"
          />
          <img
            src={isWallet ? wallet_logo : ring_logo}
            alt="Kort Valuta Logo"
            className="h-auto w-[10rem] md:w-[15rem]"
          />
        </picture>
      </Link>
    </div>
    <Link
      to={isWallet ? "/wallet/contact" : "/ring/contact"}
      aria-label="contact"
      title="contact"
    >
      <button
        className="hidden md:block px-5 py-[5%] border-2 rounded-full border-white  bg-common-blue 
      font-inter text-xs font-bold tracking-widest"
      >
        <div className="flex justify-center items-center text-white">
          <img src={Envelops} alt="" className="w-4 h-5 mr-3" />
          <p>いますぐ資料請求</p>
        </div>
      </button>
    </Link>
  </div>
);
export default Header;
