import { Routes, Route, Navigate } from "react-router-dom";
import HomeView from "../screens/home/Home.View";
import RingHomeView from "../screens/home/RingHome.View";
import ContactView from "../screens/contact/Contact.View";
import Page404 from "../screens/home/Page404.View";

const Navigator = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/wallet" />} />
    <Route path="/wallet" element={<HomeView />} />
    <Route path="/ring" element={<RingHomeView />} />
    <Route path="/wallet/contact" element={<ContactView isWallet />} />
    <Route path="/ring/contact" element={<ContactView isWallet={false} />} />
    <Route path="*" element={<Page404 />} />
  </Routes>
);

export default Navigator;
