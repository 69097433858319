import React, { lazy } from "react";
import header_logo from "../../assets/twooca_wallet_white_wide.svg";
import header_logo1 from "../../assets/twooca_wallet_white.svg";
import header_hand from "../../assets/home/twooca_phone1_2.png";
import header_hand_small1 from "../../assets/home/twooca_phone2_1.png";
import header_hand_small from "../../assets/home/twooca_phone2.png";
import header_hand_wrist from "../../assets/home/twooca_phone1.png";
import imgCard from "../../assets/home/img_payment_card.png";
import imgCardSm from "../../assets/home/img_payment_card_sm.png";
import imgCardMd from "../../assets/home/img_payment_card_md.png";
import imgAtm from "../../assets/home/atm.svg";
import imgBenefitTalk from "../../assets/home/happy.png";
import "../../styles/wallet/index.css";

const NavBottomBtn = lazy(
  () => import("../../components/bottomBtnForPhone/NavBottomBtn")
);
const Partner = lazy(() => import("./walletSection/Partner"));
const Footer = lazy(() => import("../../components/footer/Footer"));
const Cooperate = lazy(() => import("./section/Cooperate"));
const ContactBtn = lazy(() => import("../../components/contactBtn/ContactBtn"));
const ServiceDetail = lazy(() => import("./walletSection/ServiceDetail"));
const Feature = lazy(() => import("../../components/feature/Feature"));

const HomeView: React.FC = () => (
  <div className="w-full">
    {/* fixed Button */}
    <ContactBtn isWallet />
    {/* fixed Button for phone */}
    <NavBottomBtn isWallet />
    {/* hero section */}
    <div
      className="relative flex flex-col w-full px-[5%] md:pl-[5%] pr-0 bg-hero-wallet
  bg-cover bg-center overflow-hidden"
    >
      <div className="absolute inset-x-0 inset-y-0 z-20 bg-black opacity-20" />
      {/* logo */}
      <div className="flex justify-center md:justify-start items-center z-30 mt-8 md:mt-14 xl:mt-12 ">
        <picture>
          <source
            media="(max-width: 767px)"
            srcSet={header_logo1}
            sizes="767px"
          />
          <source
            media="(min-width: 767px)"
            srcSet={header_logo}
            sizes="1000px"
          />
          <img
            src={header_logo}
            alt="Kort Valuta Logo"
            className="w-[15rem] md:w-[25rem] xl:w-[35rem]"
          />
        </picture>
      </div>
      {/* content */}
      <div className="flex flex-wrap justify-between items-center space-y-[2rem] z-30 mt-20 md:mt-6">
        <div className="w-full md:w-3/5 md:-translate-y-10 text-hero-title">
          モバイルに決済機能を搭載したデジタルID
          <div className="text-hero-title-small">
            （社員証・会員証・学生証・診察券）
          </div>
        </div>
        <div className="w-full md:w-2/5 text-center">
          <picture>
            <source
              media="(max-width: 380px)"
              srcSet={header_hand_small}
              className="max-w-full translate-x-[15%]"
            />
            <source
              media="(max-width: 640px)"
              srcSet={header_hand_small1}
              className="max-w-full translate-x-[15%]"
            />
            <source
              media="(min-width: 640px; max-width: 768px)"
              srcSet={header_hand}
              className="inline max-w-full"
            />
            <source media="(min-width: 768px)" srcSet={header_hand_wrist} />
            <img src={header_hand_small} alt="" className="translate-x-[15%]" />
          </picture>
        </div>
      </div>
    </div>
    {/* IntegratedServices */}
    <div className="m-auto box-border content">
      <p className="w-full m-auto py-16 lg:py-32 xl:py-48 text-service-title">
        社員証、会員証や診察券が
        <br />
        アプリひとつで完結
      </p>
      <ServiceDetail />
    </div>

    {/* Partner */}
    <Partner />

    {/* VISACard */}
    <div className="w-full m-auto bg-gray-100">
      <div className="md:flex content m-auto">
        {/* ビザカード */}
        <div
          className="hidden md:block md:basis-1/2 m-auto md:m-0 md:pt-28 lg:pt-40 xl:pt-52 xxl:pt-72 
          md:ml-5 lg:ml-7 xl:ml-10 xxl:ml-9"
        >
          <picture>
            <source media="(max-width: 768px)" srcSet={imgCardMd} />
            <source media="(min-width: 768px)" srcSet={imgCard} />
            <img src={imgCard} alt="imgCard" className="w-full" />
          </picture>
        </div>
        {/* Visaカードを使用する方が便利です */}
        <div className="md:basis-1/2 md:ml-[2%] xxl:ml-[2.6%]">
          <h1
            className="w-auto pt-20 md:pt-20 lg:pt-28 xl:pt-36 xxl:pt-48 pb-20 md:pb-12 lg:pb-16 xl:pb-24
            xxl:pb-32 text-payment-title"
          >
            Visaプリペイドカードで
            <br />
            お買い物がもっと便利に！
          </h1>
          {/* ビザカード */}
          <img
            className="md:hidden block w-auto m-auto"
            src={imgCardSm}
            alt="imgCard"
          />
          <p
            className="w-auto m-auto md:m-0 pt-14 md:pt-0 px-[6.28%] md:px-0 pb-16 md:pb-9 lg:pb-11 xl:pb-14 xxl:pb-20
            text-payment-detail1"
          >
            Visaタッチ機能搭載！署名、暗証番号が不要、かざすだけでスマート支払
            <br />
            <br />
            アプリ上でVisaバーチャルプリペイドカードを即時に発行できますが、アプリ内申請でプリペイドカードも所有できます
          </p>
          {/* ユートベ紹介動画 */}
          <div className="w-auto mx-[12%] md:m-0 md:mr-40 lg:mr-56 xl:mr-80 xxl:mr-[26rem]">
            <iframe
              className="w-full aspect-video"
              src="https://www.youtube-nocookie.com/embed/FgkNL1VASpw"
              title="YouTube video player"
              allow="
              accelerometer; 
              autoplay; 
              clipboard-write; 
              encrypted-media; 
              gyroscope; 
              picture-in-picture; 
              web-share"
              allowFullScreen
              loading="lazy"
            />
          </div>
          <p
            className="w-auto m-auto md:m-0 pt-4 md:pt-2 lg:pt-2.5 xl:pt-3.5 xxl:pt-5 px-[7%] md:px-0 pb-20
            md:pb-24 xxl:pb-44 text-payment-detail2"
          >
            【第一段】TwooCaのリアルカードを発行しよう
          </p>
        </div>
      </div>
      {/* FlexibleChargingOptions */}
      <div className="m-auto px-[6.9%] md:px-[5.5%] xxl:px-24 pb-20 lg:pb-24 xl:pb-36 xxl:pb-48 content">
        <div className="md:flex pt-14 md:pt-0 pb-12 md:pb-0 md:pl-10 lg:pl-[7%] rounded-3xl bg-white">
          <div className="md:basis-1/2 w-full">
            {/* トップアップの概要 */}
            <h1 className="text-atm-title">
              セブン銀行ATM、
              <br />
              クレジットカードや銀行振込で
              <br />
              チャージができる
            </h1>
            {/* より便利な指紋およびセンサー機能 */}
            <p
              className="w-auto py-10 md:pt-6 lg:pt-8 xl:pt-12 xxl:pt-16 px-[7%] md:px-0 md:pb-8 lg:pb-10 xl:pb-14
              xxl:pb-20 text-payment-detail1"
            >
              Visaタッチ機能搭載！署名、暗証番号が不要、かざすだけでスマート支払
            </p>
            {/* ユートベ紹介動画 */}
            <div className="w-auto mx-[6%] md:mx-0 md:mr-28 lg:mr-40 xl:mr-56 xxl:mr-72">
              <iframe
                className="w-full aspect-video"
                src="https://www.youtube-nocookie.com/embed/uSioSjD5sVk"
                title="YouTube video player"
                allow="
              accelerometer; 
              autoplay; 
              clipboard-write; 
              encrypted-media; 
              gyroscope; 
              picture-in-picture; 
              web-share"
                allowFullScreen
                loading="lazy"
              />
            </div>
            {/* セブン銀行でチャージをしよう */}
            <p
              className="w-auto m-auto pt-3.5 md:pt-2 lg:pt-3 xl:pt-4 xxl:pt-5 px-[5%] md:px-0 pb-10 md:pb-14
              xl:pb-20 xxl:pb-24 text-payment-detail2"
            >
              【第二段】手数料無料！セブン銀行でチャージをしよう
            </p>
          </div>
          {/* 従来のトップアップのジレンマの例図 */}
          <div className="md:basis-1/2">
            <img
              src={imgAtm}
              alt="imgAtm"
              className="w-auto m-auto px-[2.5%] md:px-0 rounded-3xl"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
    {/* PointRewards */}
    <div className="md:flex content m-auto bg-white">
      <div className="hidden md:block md:basis-[53%]">
        <img
          src={imgBenefitTalk}
          alt="imgBenefitTalk"
          className="md:w-full h-auto md:mt-24 lg:mt-32 xl:mt-44 xxl:mt-56"
          loading="lazy"
        />
      </div>
      {/* あなたのライフスタイルに合わせて */}
      <div className="md:basis-[47%] md:ml-[4%]">
        <h1
          className="w-auto m-auto md:m-0 py-20 md:pt-28 lg:pt-36 xl:pt-48 xxl:pt-[17rem] md:pb-12 lg:pb-16
          xl:pb-24 xxl:pb-32 text-payment-title"
        >
          ライフスタイルに合わせた
          <br />
          ポイント付与で
          <br />
          幸福度アップ
        </h1>
        {/* 画像の例 */}
        <img
          src={imgBenefitTalk}
          alt="imgBenefitTalk"
          width="390px"
          height="280px"
          className="block md:hidden w-full"
          loading="lazy"
        />
        {/* ポイント交換特典 */}
        <p
          className="w-auto m-auto md:m-0 pt-12 md:pt-0 px-[6.6%] md:px-0 pb-24 lg:pb-0 lg:mb-32 xl:mb-40 xxl:mb-56
          text-payment-detail1"
        >
          例えば社員証を導入した企業様の場合、従業員に毎月福利厚生ポイントを一括付与！様々なギフトカードやポイントなどに交換可能です
          <br />
          <br />
          ポイントの使い道を従業員ひとりひとりが選べることで、モチベーションの向上に繋がります
        </p>
      </div>
    </div>

    {/* Features */}
    <div className="bg-gray-100 w-full">
      <div className="m-auto content">
        <h1 className="m-auto py-20 md:pb-20 xl:py-36 text-feature-title">
          TwooCaは
          <br />
          従業員のエンゲージメントを高める
          <br />
          従業員向け特典プラットフォームです
        </h1>
        <Feature />
      </div>
    </div>
    <Cooperate isWallet />
    <Footer />
  </div>
);

export default HomeView;
