import Header from "../../components/navBar/Header";
import Footer from "../../components/footer/Footer";
import page404img from "../../assets/home/page404.svg";
import "../../styles/wallet/index.css";

const Page404 = () => (
  <div>
    <Header isWallet />
    <div className="h-screen w-screen bg-gray-50 flex items-center">
      <div className="container flex flex-col md:flex-row items-center justify-between px-5 text-gray-700">
        <div className="w-full lg:w-1/2 mx-8">
          <div className="text-4xl text-common-blue font-dark font-extrabold mb-8">
            お探しのページは見つかりませんでした。
          </div>
          <p className="text-2xl md:text-3xl font-light leading-normal mb-8">
            該当のURLが移動または削除されているか、あるいは直接URLを入力されている場合は入力ミスの可能性があります。
          </p>

          <a
            href="/"
            className="px-5 inline py-3 shadow-2xl text-white border rounded-lg bg-common-blue hover:bg-green-700"
          >
            ページトップへ戻る
          </a>
        </div>
        <div className="w-full lg:flex lg:justify-end lg:w-1/2 mx-5 my-12">
          <img
            src={page404img}
            className=""
            alt="Page not found"
          />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
export default Page404;
