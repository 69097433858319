import React from "react";

// props
export interface IFeatureProps {
  url?: string;
}

const WebView: React.FC<IFeatureProps> = (props) => {
  const { url } = props;
  return (
    <div className="w-full h-[1750px] md:h-[1630px]">
      <iframe
        className="w-full h-[1750px] md:h-[1630px] m-0"
        title="iframe"
        src={url}
      />
    </div>
  );
};
export default WebView;
