import React from "react";
import { Link } from "react-router-dom";
import kortvalutaLogo from "../../assets/kortvaluta_logo_white.svg";

/**
 * フッター
 */
const Footer: React.FC = () => (
  <footer className="w-full text-white bg-common-blue">
    <div className="content m-auto px-[6.5%] font-inter">
      {/* footer_title */}
      <div className="md:flex">
        <Link to="/" aria-label="" title="home">
          <img
            className="w-60 md:w-40 lg:w-56 xl:w-80 m-auto md:m-0 pt-16 xl:pt-28 pb-10"
            src={kortvalutaLogo}
            alt=""
          />
        </Link>
        <h1 className="m-auto md:ml-[4%] md:pt-8 xl:pt-20 text-footer-title">
          株式会社 Kort Valuta ( コートヴァリュタ )
        </h1>
      </div>
      {/* footer-content */}
      <p className="m-auto md:ml-0 pt-14 xl:pt-20 pb-16 xl:pb-8 md:py-5 text-footer-detail1">
        前払式支払手段（第三者型）発行者【登録番号】関東財務局長第00743号
        <br />
        資金移動業者【登録番号】関東財務局長第00096号
      </p>
      {/* footer_copyright */}
      <p className="pb-10 md:pb-8 text-footer-detail2">© Kort Valuta Inc.</p>
    </div>
  </footer>
);
export default Footer;
